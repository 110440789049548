<template>
  <div class="page-wrap">
    <div class="section-wrap">
      <div class="wrapper-left">
        <breadcrumbs :crumbs="pageData.crumbs"></breadcrumbs>
        <div class="wrapper-content">
          <div class="wrapper-list">
            <div class="wrapper-content-flex">
              <div class="poster">
                <img :src="state.coverImage" />
              </div>
              <div class="info">
                <div class="block">
                  <div class="block-tit">基础</div>
                  <div class="cont">
                    <div>
                      <span class="lbl">姓名：</span
                      ><span>{{ state.userNickname }}</span>
                    </div>
                    <div><span class="lbl">性别：</span><span>男</span></div>
                    <div>
                      <span class="lbl">行业：</span
                      ><span>{{ state.userIndustry }}</span>
                    </div>
                    <div>
                      <span class="lbl">评星级别：</span
                      ><el-rate v-model="rate" disabled></el-rate>
                    </div>
                  </div>
                </div>
                <div class="block">
                  <div class="block-tit">能力介绍</div>
                  <div class="cont">
                    <div>
                      <span class="lbl">用户职级：</span
                      ><span>{{ state.userTitle }}</span>
                    </div>
                    
                  </div>
                </div>
               
              </div>
            </div>
            <div class="wrapper-content">
              <div class="block">
                  <div class="block-tit">简介</div>
                  <div class="cont">
                    {{ state.content }}
                    
                  </div>
                </div>
            </div>
          </div>

          <div class="wrapper-right">
            <QuickPublishProject path="project" buttonLabel="发布项目"></QuickPublishProject>
            <BlockSuggestWXGroup class="box-suggest"></BlockSuggestWXGroup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import ItemProject from '@/components/ItemProject.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestWXGroup from '@/components/BlockSuggestWXGroup.vue'
import { Api } from '@/services/http'

const rate = ref(0)
const store = useStore()
const route = useRoute()
let pageData = reactive({
  info: {},
  crumbs: [],
  businessItems: [],
})
let state = computed(() => pageData.info)
const projectId = computed(() => {
  return route.params.id
})
watch(projectId, newId => {
  console.log('newId', newId)
  mounted()
})

//** 行业数据载入 */
const industry = computed(() => {
  return store.state.base.businessItems
})
const userIndustry = computed(() => {
  const item = optionsIndustry.value.find(
    item => item.value == pageData.info.userIndustry
  )

  if (item) return item.label
  return '未知'
})
const optionsIndustry = computed(() => {
  let items = []
  if (pageData.businessItems.length) {
    items = pageData.businessItems
  }
  if (store.state.base.businessItems.length) {
    items = store.state.base.businessItems
  }

  const find = items.find(item => item.type == 'industry')
  if (find) {
    return find.items
  } else {
    return []
  }
})
watch(industry, newData => {
  pageData.businessItems = [...newData]
})

const mounted = async () => {
  const id = route.params.id
  const info = await Api.get('/client-api/person/get?id=' + id)
  pageData.info = { ...info }
  rate.value = info.userStarLevel
  pageData.crumbs = [
    {
      name: info.groupName,
    },
  ]
}
mounted()
</script>
<style lang="scss" scoped>
.section-wrap {
  padding-bottom: 30px;
  .wrapper-left {
    padding-top: 30px;
  }
  .wrapper-content {
    display: flex;
    .wrapper-right {
      flex-basis: 260px;
      margin-left: 30px;
    }
    .box-suggest {
      margin-top: 20px;
    }
  }
  .wrapper-list {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    
    h3 {
      padding-bottom: 20px;
      font-size: 30px;
    }
  }
  .wrapper-content-flex{
    display: flex;
  }
  .wrapper-page {
    padding: 20px 0;
  }
  .poster {
    width: 180px;
    height: 180px;
    border: 1px solid #ededed;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .info {
    flex: 1;
    text-align: left;
    padding-left: 20px;
    .title {
      margin-bottom: 20px;
    }
  }
  .block {
    text-align: left;
    margin-bottom: 15px;
    .lbl {
      display: inline-block;
      width: 100px;
    }
    .block-tit {
      height: 16px;
      border-left: 5px solid #fc9507;
      line-height: 16px;
      padding-left: 10px;
      font-weight: bold;
    }
    .tag {
      display: inline-block;
      margin-left: 10px;
      border: 1px solid #ececec;
      border-radius: 3px;
      padding: 1px 5px;
      background-color: white;
    }

    .cont {
      padding: 10px;
      font-size: 14px;
    }
  }
  .block-qrcode {
    img {
      width: 145px;
      height: 145px;
    }
  }
}
</style>
